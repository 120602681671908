import React from "react"

import Layout from "~components/base/layout"
import SEO from "~components/base/seo"
import "../styles/pages/_tank-you.scss";

const ThankYouPage = () => (
    <Layout>
        <SEO title="Thank You" />
        <section className="section-intro section-thank-you">
            <div className="container">
                <h1 className="title">Thank You!</h1>
                <h3 className="subtitle">Alright, we are almost ready to go.</h3>
                <p><strong>Step 1:</strong> You made a great decision.</p>
                <p><strong>Step 2:</strong> We are going to bombard you with emails and notifications <br/>
                as we get you set up in our Project Management Platform + gather and<br/>
                organize critical project information </p>
                <p><strong>Step 3:</strong> Once we know who your assigned project manager and team<br/>
                members are, we will make sure to get in touch and kick this project-off! </p>
            </div>
        </section>
    </Layout>
);

export default ThankYouPage
